<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorClassCd"
            label="업체분류"
            v-model="searchParam.vendorClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorTypeCd"
            label="업체유형"
            v-model="searchParam.vendorTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-tree-table
          ref="vendorTable"
          title="도급업체 목록"
          parentProperty="upVendorCd"
          customID="vendorCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :usePaging="false"
          :filtering="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <q-form ref="editForm">
          <c-card title="도급업체 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && data.companyFlag=='N' && dataeditable && selectedVendorCd !== ''"
                  :url="resetUrl"
                  :isSubmit="isReset"
                  :param="data"
                  mappingType="PUT"
                  label="관리자 비밀번호 초기화"
                  icon="password"
                  @beforeAction="resetPwd"
                  @btnCallback="resetPwdCallback" />
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addVendor" />
                <c-btn
                  v-if="editable"
                  :disabled="!saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="업체명"
                  name="vendorName"
                  v-model="data.vendorName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-tree-vendor
                  :editable="editable && dataeditable"
                  label="상위업체"
                  name="upVendorCd"
                  v-model="data.upVendorCd">
                </c-tree-vendor>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="false"
                  label="업체코드"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="사업자번호"
                  name="bizNo"
                  mask="##########"
                  v-model="data.bizNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  codeGroupCd="VENDOR_CLASS_CD"
                  :required="true"
                  :editable="editable && dataeditable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="vendorClassCd"
                  label="업체분류"
                  v-model="data.vendorClassCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  codeGroupCd="VENDOR_TYPE_CD"
                  :required="true"
                  :editable="editable && dataeditable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="vendorTypeCd"
                  label="업체유형"
                  v-model="data.vendorTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="대표자명"
                  name="representName"
                  v-model="data.representName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="대표전화번호"
                  name="phoneNo"
                  v-model="data.phoneNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-text
                  :editable="editable && dataeditable"
                  label="본사주소"
                  name="address"
                  v-model="data.address">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="대표이메일"
                  name="email"
                  v-model="data.email">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="담당자명"
                  name="chargeName"
                  v-model="data.chargeName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  v-if="data.companyFlag === 'N'"
                  :editable="false"
                  label="관리자 접속 ID(자동생성)"
                  name="vendorLoginId"
                  v-model="data.vendorLoginId">
                </c-text>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="본사여부"
                  name="companyFlag"
                  v-model="data.companyFlag"
                />
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
        <c-table
          v-if="data.companyFlag === 'N'"
          ref="vendorUserTable"
          title="도급업체 근무인원"
          tableId="vendorUserTable"
          :columns="grid2.columns"
          :data="data.vendorUserList"
          gridHeight="455px"
          :editable="editable && dataeditable"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="userId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && dataeditable" label="추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable && dataeditable" label="제외" icon="remove" @btnClicked="removerow" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'loginId'">
              <span v-if="props.row['dayJobFlag'] === 'Y'">
              </span>
              <span v-else>
                {{props.row['loginId']}}
              </span>
            </template>
            <template v-if="col.name === 'resetPw'">
              <q-btn
                v-if="props.row['editFlag'] !== 'C' && props.row['dayJobFlag'] !== 'Y'"
                class="glossy"
                round 
                size="sm"
                :editable="editable"
                color="blue-6"
                icon="password"
                @click.stop="saveReset2(props.row)" />
            </template>
            <template v-if="col.name === 'inputPeriod'">
              <q-btn
                v-if="props.row['editFlag'] !== 'C'"
                class="glossy"
                round 
                size="sm"
                :editable="editable"
                color="orange-6"
                icon="person_add"
                @click.stop="openPop(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-manage',
  data() {
    return {
      searchParam: {
        vendorTypeCd: null,
        vendorClassCd: null,
        vendorName: '',
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid2: {
        columns: [],
        data: [],
      },
      data: {
        companyCd: '',  // 회사코드
        afterCnt: '',
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        upVendorCd: '',
        upVendorName: '',
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        companyFlag: 'N',  // 본사여부
        portalId: '',  // 포탈 ID
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUserList: [],
        deleteVendorUserList: [],
      },
      editable: true,
      selectedVendorCd: '',
      selectedVendor: [],
      resetUrl: '',
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      isReset: false,
      saveUrl: transactionConfig.mdm.cim.vendor.insert.url,
      checkVendorUrl: '',
      saveType: 'POST',
      searchUrl: '',
      originalPortalId: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.cim.vendor.list.url;
      this.dataeditable = false;
      // api scope
      this.detailUrl = selectConfig.mdm.cim.vendor.get.url;
      this.checkVendorUrl = selectConfig.ten.company.check.vendor.url;
      this.checkUrl = selectConfig.mdm.cim.vendor.checkportalid.url;
      this.insertUrl = transactionConfig.mdm.cim.vendor.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.delete.url;
      this.resetUrl = transactionConfig.mdm.cim.vendor.reset.url;
      this.setHeader();
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getVendorList() {
      this.getList();
      if (this.selectedVendorCd == '') {
        this.reset();
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setHeader() {
      this.$comm.getComboItems('SEX_CD').then(sexFlag => {
        this.$comm.getComboItems('JOB_CD').then(jobFlag => {
          this.$comm.getComboItems('SPOT_CD').then(spotFlag => {
            // this.$comm.getComboItems('SAFETY_VENDOR_ORG_ROLE').then(roleFlag => {
              this.grid2.columns = [
                {
                  required: true,
                  name: 'userName',
                  field: 'userName',
                  label: '성명',
                  align: 'center',
                  sortable: false,
                  type: 'text',
                },
                // {
                //   name: 'projectRoleCd',
                //   field: 'projectRoleCd',
                //   valueName: 'codeName',
                //   label: '역할',
                //   align: 'center',
                //   style: "width:100px",
                //   sortable: false,
                //   type: 'select',
                //   comboItems: roleFlag,
                // },
                {
                  name: 'jobCd',
                  field: 'jobCd',
                  label: '직책',
                  align: 'left',
                  style: 'width:80px',
                  sortable: false,
                  type: 'text',
                  comboItems: jobFlag,
                },
                {
                  name: 'spotCd',
                  field: 'spotCd',
                  label: '직위',
                  align: 'left',
                  style: 'width:80px',
                  sortable: false,
                  type: 'text',
                  comboItems: spotFlag,
                },
                {
                  required: true,
                  name: 'birthDate',
                  field: 'birthDate',
                  label: '생년월일',
                  style: 'width:120px',
                  type: 'date',
                  align: 'center',
                  sortable: false,
                },
                {
                  name: 'sexCd',
                  field: 'sexCd',
                  label: '성별',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false,
                  valueName: 'codeName',
                  itemText: 'codeName',
                  itemValue: 'code',
                  type: 'select',
                  comboItems: sexFlag,
                },
                {
                  name: 'mobileNo',
                  field: 'mobileNo',
                  label: '연락처',
                  align: 'left',
                  style: 'width:140px',
                  type: 'text',
                  sortable: false,
                },
                {
                  name: 'email',
                  field: 'email',
                  label: '이메일',
                  align: 'left',
                  style: 'width:140px',
                  type: 'text',
                  sortable: false,
                },
                {
                  name: 'dayJobFlag',
                  field: 'dayJobFlag',
                  label: '일용직<br>여부',
                  align: 'center',
                  sortable: false,
                  style: 'width:60px',
                  type: 'check',
                  true: 'Y',
                  false: 'N',
                },
                {
                  name: 'loginId',
                  field: 'loginId',
                  label: '로그인ID',
                  type: 'custom',
                  align: 'center',
                  style: 'width:100px',
                  sortable: false,
                },
                {
                  name: 'resetPw',
                  field: 'resetPw',
                  label: '비밀번호<br>초기화',
                  align: 'center',
                  style: 'width:70px',
                  type: 'custom',
                  sortable: false,
                },
              ];
            // });
          });
        });
      });
    },
    rowClick(row) {
      this.selectedVendor = row;
      this.selectedVendorCd = row.vendorCd;
      
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, this.selectedVendorCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.originalPortalId = _result.data.portalId;
        this.dataeditable = true;
      },
      () => {
      });
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedVendorCd = '';
      this.selectedVendor = [];
      
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        companyCd: '',  // 회사코드
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        companyFlag: 'N',  // 본사여부
        deleteFlag: 'N',  // 삭제여부
        manageFlag: 'N',  // 관리자여부
        portalId: '',  // 포탈 ID
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUserList: [],
      };
    },
    addVendor() {
      this.rowRemoveSelect();
      this.selectedVendorCd = '';
      this.selectedVendor = [];
      
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        companyCd: '',  // 회사코드
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        companyFlag: 'N',  // 본사여부
        deleteFlag: 'N',  // 삭제여부
        manageFlag: 'N',  // 관리자 여부
        portalId: '',  // 포탈 ID
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUserList: [],
      };
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.data.vendorUserList.splice(0, 0, {
        companyCd: this.$store.getters.user.companyCd,
        userId: uid(),
        empNo: '',
        userName: '',
        loginId: '',
        loginPwd: this.data.bizNo,
        deptCd: this.data.vendorCd,
        sexCd: null,
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        retireFlag: 'N',
        projectRole: null,
        retireDate: '',
        dayJobFlag: 'Y',
        managerFlag: 'N',
        deleteFlag: 'N',  // 삭제여부
        innerFlag: 'N',
        editFlag: 'C'
      })
    },
    removerow() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteVendorUserList) {
            this.data.deleteVendorUserList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteVendorUserList, {
              userId: item.userId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteVendorUserList.push(item);
          }

          this.data.vendorUserList = this.$_.reject(this.data.vendorUserList, item);
        })
        this.$refs['vendorUserTable'].$refs['compo-table'].clearSelection();
      }
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkVendorUrl;
          this.$http.param = {
            companyCd: this.$store.getters.user.companyCd,
          }
          this.$http.type = 'GET';
          this.$http.request((_result2) => {
            if (Number(_result2.data['nowVendorCnt']) < Number(_result2.data['maxVendorCnt'])) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  if (this.saveType === 'POST') {
                    this.data.afterCnt = Number(_result2.data['nowVendorCnt']) + 1
                  } else {
                    this.data.afterCnt = Number(_result2.data['nowVendorCnt'])
                  }
                  this.data.companyCd = this.$store.getters.user.companyCd
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '등록할 수 있는 도급업체는 최대 ' + _result2.data['maxVendorCnt'] + '개 입니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    resetPwd() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '관리자 로그인 비밀번호를 초기화 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          this.isReset = !this.isReset;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.vendorCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({vendorCd: _result.data})
    },
    resetPwdCallback() {
      window.getApp.$emit('ALERT', {
        title: '안내', // 안내
        message:
        '초기비밀번호(사업자번호)로 초기화 되었습니다.',
        type: 'success', // success / info / warning / error
      });
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSearchList();
      this.selectedVendorCd = '';
    },
    saveReset2(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '사용자 비밀번호를 사업자번호로 초기화 하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.resetUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            bizNo: this.data.bizNo,
            chgUserId: this.$store.getters.user.userId,
            vendorCd: this.data.vendorCd,
            vendorLoginId: data.loginId,
            loginPwd: '',
          }
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message:
              '초기비밀번호(사업자번호)로 초기화 되었습니다.',
              type: 'success', // success / info / warning / error
            });
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    openPop(row) {
      console.log(row)
      this.popupOptions.title = row.userName + '의 투입기간 관리';
      this.popupOptions.param = {
        userId: row.userId
      };
      this.popupOptions.target = () => import(`${'./vendorInputPeriod.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
